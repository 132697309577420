<template>
  <div class="orga">
    <vue-tree style="width: 100%; height: 80vh; border: none;" :dataset="richMediaData" :config="treeConfig">
      <template v-slot:node="{ node, collapsed }">
        <div class="rich-media-node" style="">
          <span style="font-weight: bold;">{{ node.name }}</span>
        </div>
      </template>
    </vue-tree>
  </div>
</template>

<script>
    export default {
        name: 'treemap',
        data() {
            return {
                richMediaData: {
                    name: 'Direction Générale',
                    children: [
                        {
                            name: 'Service commerciale',
                            children: [
                                { name: "Client",
                                    children: [
                                        { name: "Sav",},
                                        { name: "Marketing",},
                                    ]
                                },
                                { name: "Vente",
                                    children: [
                                            { name: "Sav",},
                                            { name: "Vente",},
                                        ]
                                }
                            ]
                        },
                        {
                            name: 'Service financier',
                            children: [
                                { name: 'Paie', },
                                { name: 'Comptabilité', },
                                { name: 'Contrôle de gestion', }
                            ],
                        },
                        {
                            name: 'Service production',
                        },
                        {
                            name: 'Service administratif',
                            children: [
                                { name: 'Recrutement', },
                                { name: 'Gestion du personnel', },
                                { name: 'Audit interne', },
                                { name: 'Service juridique', }
                            ],
                        },
                        {
                            name: 'Service technique',
                            value: 300,
                        }
                    ]
                },
                treeConfig: { nodeWidth: 200, nodeHeight: 80, levelHeight: 150 }
            }
        }
    }
</script>

<style scoped>

    .orga {
        display: flex  !important;
        flex-direction: column  !important;
        align-items: center  !important;
    }

    .rich-media-node {
        display: flex  !important;
        flex-direction: column  !important;
        align-items: flex-start  !important;
        margin: 8px;
        padding: 8px;
        justify-content: center !important;
        color: white;
        background-color: #007FFF;
        border-radius: 4px;
    }
</style>