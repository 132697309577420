<template>
    <el-tabs type="border-card" class="m-2" style="height:85vh;">
        <el-tab-pane label="Demandes">
            <el-empty description="Pas de données pour la période"></el-empty>
        </el-tab-pane>
        <el-tab-pane label="Entreprise" style="overflow: hidden;">
            <v-row>
                <Organig/>
            </v-row>
        </el-tab-pane>
        
    </el-tabs>
</template>

<script>

    import Organig from '@/components/dashboard/administration/entreprise/Organigram.vue';


    export default {

        components: {
            Organig
        },

        data(){

            return {

            
            }
        }
    }

</script>

<style>

</style>